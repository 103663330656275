<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰{{ mode === "modify" ? " 수정" : "" }}하기<router-link
        to=""
        @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_28">
      <div class="top">
        <div class="box">
          <h4>리뷰할 카테고리</h4>
          <p v-if="parseInt(this.categoryNumber2) === 48">
            뉴스 > 실시간 리뷰어 속보
          </p>
          <p v-else>
            뉴스 > {{ categoryTitle.title1 }} > {{ categoryTitle.title2 }}
          </p>
        </div>
      </div>
      <div class="sel_info">
        <ul class="depth_5">
          <li>
            <router-link to="" @click.native="imageOpen"
              ><img src="@/assets/images/sub/review_sel_ico_1.png" alt="" />
              <p>메인사진</p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="image"
              ><img src="@/assets/images/sub/review_sel_ico_2.png" alt="" />
              <p>사진</p></router-link
            >
          </li>
          <li
            v-if="
              (parseInt(categoryTitle.number) === 15 ||
                parseInt(categoryTitle.number) === 16) &&
              parseInt(this.categoryNumber2) !== 48
            "
          >
            <router-link to="" @click.native="openInput('org')"
              ><img
                src="@/assets/images/sub/review_sel_ico_3.png"
                alt=""
                :style="mode == 'modify' ? 'opacity:0.3' : ''"
              />
              <p :style="mode == 'modify' ? 'opacity:0.3' : ''">
                원문링크
              </p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="openInput('link')"
              ><img src="@/assets/images/sub/review_sel_ico_3.png" alt="" />
              <p>링크</p></router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="openInput('frame')"
              ><img src="@/assets/images/sub/review_sel_ico_4.png" alt="" />
              <p>동영상링크</p></router-link
            >
          </li>
        </ul>
        <div class="link_wrap">
          <input
            type="file"
            name="filedata"
            @change="fileUpoload"
            style="display: none"
          />

          <div class="link_top" v-if="input">
            <input type="text" name="linkVal" v-model="linkVal" /><router-link
              to=""
              @click.native="setValue"
              >완료</router-link
            >
          </div>
          <!--원문링크-->
          <div class="link_bottom" v-if="input === 'org'">
            리뷰할 원문 뉴스 기사 url을 복사해 여기에 붙여넣기 한 후 옆의 [완료]
            버튼을 눌러주세요.
          </div>
          <!--링크-->
          <div class="link_bottom" v-if="input === 'link'">
            리뷰에 필요한 외부 내용 url을 복사해 여기에 붙여넣기 한 후 옆의
            [완료] 버튼을 눌러주세요. url은 작성 중인 글 바로 아래 줄에
            들어갑니다
          </div>
          <!--동영상링크-->
          <div class="link_bottom" v-if="input === 'frame'">
            리뷰에 필요한 외부 동영상 url을 복사해 여기에 붙여넣기 한 후 옆의
            [완료] 버튼을 눌러주세요. 해당 동영상은 임베디드 되어 (embedded)
            작성중인 글 바로 아래 줄에 들어갑니다.
          </div>
        </div>
      </div>
      <div class="cont_info">
        <div class="sect_title">
          <input
            type="text"
            name=""
            @input="changeTitle"
            :value="title"
            maxlength="50"
            placeholder="리뷰 제목을 써주세요."
          />
        </div>
        <div class="sect_thum">
          <table>
            <tbody>
              <tr>
                <td>
                  <div class="img" @click="imageOpen">
                    <img
                      src="@/assets/images/sub/status_ico.png"
                      alt=""
                      ref="mainImage"
                      @error="replaceImg"
                    />
                  </div>
                </td>
                <td v-if="!title">
                  위 메뉴에서 [메인사진] 버튼을 눌러 메인 사진을 등록해주세요.
                </td>
                <td v-else>
                  {{ title }}
                </td>
              </tr>
              <tr
                v-if="
                  (parseInt(categoryTitle.number) === 15 ||
                    parseInt(categoryTitle.number) === 16) &&
                  parseInt(this.categoryNumber2) !== 48
                "
              >
                <td>
                  <div class="img" @click="openInput('org')">
                    <img
                      src="@/assets/images/sub/status_ico.png"
                      alt=""
                      ref="newsImage"
                      @error="replaceImg"
                    />
                  </div>
                </td>
                <td v-if="!this.newsTitle">
                  위 메뉴에서 [원문 링크] 버튼을 눌러 리뷰할 원문 기사 url을
                  입력해주세요.
                </td>
                <td v-else>
                  <h4 v-html="newsTitle" class="news_title">
                    {{ newsTitle }}
                  </h4>
                  <p v-html="newsSummary" class="news_content">
                    {{ newsSummary }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sect_con">
          <vue-editor
            v-model="content"
            use-custom-image-handler
            :editorToolbar="customToolbar"
            @image-added="handleImageAdded"
            :placeholder="`
여기에 리뷰할 내용을 써주세요.
또는 유튜브 리뷰의 경우, [동영상 링크] 버튼을 눌러 유튜브의 해당 URL을 입력해주세요.
사진과 동영상의 경우, 저작권이 침해되지 않는 것들만 올려주세요. 저작권 침해에 대해 회사는 책임지지 않습니다.`"
          ></vue-editor
          ><!--뉴스-->
        </div>
        <div class="sect_grade" v-if="mode === null">
          <table summary="">
            <tbody>
              <tr>
                <td>뉴스 중요도 평점을 매겨주세요.</td>
                <td>
                  <!-- <router-link to="" @click.native="minus">▼</router-link
                  >&nbsp;<input
                    type="text"
                    name=""
                    maxlength="3"
                    v-model="score"
                    readonly
                  />점&nbsp;<router-link to="" @click.native="plus"
                    >▲</router-link
                  > -->
                  <select
                    name=""
                    id=""
                    style="vertical-align: middle; width: 50px; height: 25px"
                    v-model="score"
                  >
                    <template v-for="(n, idx) in 50">
                      <option :key="idx" :value="n / 10" v-if="n / 10 >= 1">
                        {{ n / 10 }}
                      </option>
                    </template>
                  </select>
                  점
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--뉴스-->
        <div
          class="sect_text"
          v-if="
            (parseInt(categoryTitle.number) === 15 ||
              parseInt(categoryTitle.number) === 16) &&
            parseInt(this.categoryNumber2) !== 48
          "
        >
          [언론사 뉴스/스포츠] 리뷰는 해당 기사 URL을 꼭 입력해주세요. 입력을 안
          하면 등록이 안 됩니다.
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="setNewsReviewRegister">{{
          mode === "modify" ? "수정" : "등록"
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import imgMixin from "@/mixin/imgMixin";
export default {
  data() {
    return {
      categoryNumber1: this.$route.params.category1 || null,
      categoryNumber2:
        this.$route.params.category2 || this.$route.params.category1,
      menu: this.$route.query.menu || null,
      category: this.$route.query.category || null,
      seq: this.$route.query.seq || null,
      mode: this.$route.query.mode || null,
      memberId: this.$route.query.memberId || null,
      pageName: this.$route.query.page_name || null,
      mainImg: "",
      thumbnail: "",
      title: "",
      newsLink: "",
      newsTitle: "",
      newsImage: "",
      newsSummary: "",
      score: parseFloat(2.5),
      input: false,
      tmpInput: false,
      content: "",
      linkVal: null,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        //["formula", "image", "video"],
        ["image", "video"],
        //["clean"], // remove formatting button
      ],
      media: process.env.VUE_APP_NEWS_MEDIA,
      realTime: process.env.VUE_APP_NEWS_REAL_TIME,
    };
  },
  mixins: [imgMixin],
  created() {
    //초기화
    this.$store.dispatch("review/resetStore");
    this.getCategoryTitle();
    if (this.mode === "modify") {
      this.getNewsReview();
    }
  },
  components: {
    VueEditor,
  },
  computed: {
    ...mapState("review", [
      "mainImage",
      "result",
      "msg",
      "newsContents",
      "categoryTitle",
      "imgUrl",
      "reviewSeq",
      "reviewData",
    ]),
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    async getCategoryTitle() {
      if (parseInt(this.categoryNumber2) !== 48) {
        await this.$store.dispatch("review/getCategoryTitle", {
          seq: this.categoryNumber2,
        });
        if (!this.result) {
          this.$toast.default(this.msg);
          this.$router.push("/review/review_choice");
        }
      }
    },
    plus() {
      if (parseFloat(this.score) < 5.0) {
        this.score = parseFloat((parseFloat(this.score) + 0.1).toFixed(1));
      }
    },
    minus() {
      if (parseFloat(this.score) > 1.0) {
        this.score = parseFloat((parseFloat(this.score) - 0.1).toFixed(1));
      }
    },
    changeTitle(e) {
      this.title = e.target.value;
    },
    async fileUpoload(e) {
      // this.loadImage(e);
      const image = document.querySelector("input[name='filedata'").files[0];
      const allow_ext = ["jpg", "png", "jpeg", "gif"];
      if (!image) {
        this.$toast.default("메인 이미지 파일을 선택 해주세요.");
        return false;
      }
      let ext = image.name.split(".").pop().toLowerCase();
      const ext_check = allow_ext.some((item) => item === ext);
      if (!ext_check) {
        this.$toast.default("허용되지 않는 파일 입니다.");
        document.querySelector("input[name='filedata'").value = null;
        return false;
      }
      let formData = new FormData();
      formData.append("filedata", image);
      await this.$store.dispatch("review/submitMainImg", formData);
      document.querySelector("input[name='filedata'").value = null;
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.$refs["mainImage"].src = this.mainImage.thumbnail;
      this.thumbnail = this.mainImage.thumbnail;
      this.mainImg = this.mainImage.main;
    },
    imageOpen() {
      document.querySelector("input[name='filedata'").click();
    },
    openInput(type) {
      if (this.mode === "modify" && type === "org") return false;
      if (this.tmpInput === type) {
        this.input = false;
        this.tmpInput = false;
        return false;
      }
      this.input = type;
      this.tmpInput = this.input;
    },
    setValue() {
      if (this.linkVal) {
        if (this.input === "org") {
          this.addNewsLink();
        } else if (this.input === "link") {
          this.addLink();
        } else if (this.input === "frame") {
          this.embed();
        } else {
          this.$toast.default("지원 하지 않는 타입입니다.");
        }
      }
    },
    image() {
      document.querySelector(".ql-image").click(); //$refs 제외
    },
    embed() {
      const html = `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${this.extractVideoUrl(
        this.linkVal
      )}"></iframe><p><br /></p>`;
      this.content += html;
      this.isFrame = false;
      this.linkVal = null;
      this.input = false;
    },
    async addNewsLink() {
      let urlTest = /^http|s:\/\/.*/;
      this.newsLink = this.linkVal;
      if (!urlTest.test(this.newsLink)) {
        this.$toast.default(
          "원문 링크는 http:// 또는 https:// 를 포함한는  뉴스 기사 전체 URL을 입력해주세요."
        );
        return false;
      } else {
        await this.$store.dispatch("review/getMetaData", {
          url: this.newsLink,
        });
        this.newsTitle = this.newsContents.og_title;
        this.newsImage = this.newsContents.og_image;
        this.newsSummary = this.newsContents.og_description;

        this.$refs["newsImage"].src = this.newsImage;
        this.linkVal = null;
        this.input = false;
      }
    },
    addLink() {
      const html = `<a href="${this.linkVal}" target="_blank">[ 관련내용 보기]</a>`;
      this.content += html;
      this.linkVal = null;
      this.input = false;
    },
    extractVideoUrl(url) {
      var match =
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
        ) ||
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
        );
      if (match) {
        return (
          (match[1] || "https") +
          "://www.youtube.com/embed/" +
          match[2] +
          "?showinfo=0"
        );
      }
      if (
        (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
      ) {
        // eslint-disable-line no-cond-assign
        return (
          (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
        );
      }
      this.linkVal = null;
      this.input = false;
      return url;
    },
    setNewsReviewRegister() {
      let urlTest = /^http|s:\/\/.*/;

      if (this.title.replace(/\s/i, "").length <= 0) {
        this.$toast.default("리뷰 제목을 작성해 주세요.");
        return false;
      }
      if (
        (parseInt(this.categoryTitle.number) === 15 ||
          parseInt(this.categoryTitle.number) === 16) &&
        parseInt(this.categoryNumber2) !== 48
      ) {
        if (this.newsLink.replace(/\s/i, "").length <= 0) {
          this.$toast.default(
            "언론사 뉴스/스포츠 리뷰는 원문 기사 URL를 입력하셔야 합니다."
          );
          this.linkVal = null;
          this.newsLink = "";
          this.openInput("org");
          return false;
        }
        if (!urlTest.test(this.newsLink)) {
          this.$toast.default(
            "원문 링크는 http:// 또는 https:// 를 포함한는  뉴스 기사 전체 URL을 입력해주세요."
          );
          this.linkVal = null;
          this.newsLink = "";
          this.openInput("org");
          return false;
        }
      }
      if (this.content.replace(/\s/i, "").length <= 0) {
        this.$toast.default("리뷰 내용을 입력해주세요.");
        return false;
      }

      if (parseInt(this.score) <= 0) {
        this.$toast.default("뉴스 중요도는 1점 보다 커야 합니다.");
        return false;
      }
      if (parseInt(this.score) > 5) {
        this.$toast.default("뉴스 중요도는 5점 만점 입니다..");
        this.score = 0;
        return false;
      }

      this.$confirm(
        `뉴스 리뷰를 ${this.mode === "modify" ? "수정" : "등록"} 하시겠습니까?`
      )
        .then(async () => {
          let formdata = new FormData();
          formdata.append("review_type", "R");
          formdata.append("title", this.title);
          formdata.append("content", this.content);
          formdata.append("main_img", this.mainImg);
          formdata.append("thumbnail", this.thumbnail);
          formdata.append("mode", this.mode); //mode 가 'modify' 이면 수정
          formdata.append("seq", this.seq); //리뷰 번호
          if (
            (parseInt(this.categoryTitle.number) === 15 ||
              parseInt(this.categoryTitle.number) === 16) &&
            parseInt(this.categoryNumber2) !== 48
          ) {
            formdata.append("news_link", this.newsLink);
            formdata.append("news_type", "link");
            formdata.append("main_score", this.score);
            formdata.append("option_category1", this.categoryNumber1);
            formdata.append("option_category2", this.categoryNumber2);
          } else {
            formdata.append("news_type", "nolink");
            formdata.append("main_score", this.score);
            if (parseInt(this.categoryNumber2) === 48) {
              formdata.append("option_category1", this.categoryNumber1);
              formdata.append("option_category2", null);
            } else {
              formdata.append("option_category1", this.categoryNumber1);
              formdata.append("option_category2", this.categoryNumber2);
            }
          }

          await this.$store.dispatch("review/setNewsReviewRegister", formdata);
          this.$toast.default(this.msg);
          if (this.result) {
            //결과에 따라 다음 로직
            let url = "";
            if (
              parseInt(this.categoryNumber1) === parseInt(this.realTime) &&
              !this.pageName
            ) {
              url = `/review/news_main/${this.categoryNumber1}?category=0&seq=${this.reviewSeq}&range=today`;
            } else if (this.pageName === "reviewer") {
              if (parseInt(this.categoryNumber1) === parseInt(this.realTime)) {
                url = `/review/reviewer_news/${this.memberId}/${this.categoryNumber1}?category=0&seq=${this.reviewSeq}&range=today`;
              } else {
                url = `/review/reviewer_news/${this.memberId}/${this.categoryNumber1}?category=${this.categoryNumber2}&seq=${this.reviewSeq}&range=today`;
              }
            } else {
              url = `/review/news_main/${this.categoryNumber1}?category=${this.categoryNumber2}&seq=${this.reviewSeq}&range=today`;
            }
            // console.log(url);
            this.$router.push(url);
          }
        })
        .catch(() => {
          return false;
        });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("filedata", file);

      await this.$store.dispatch("review/editorImgUpload", formData);
      if (this.result) {
        Editor.insertEmbed(cursorLocation, "image", this.imgUrl);
        resetUploader();
      } else {
        this.$toast.default(this.msg);
      }
      this.$store.dispatch("review/reviewStateReset");
    },
    async getNewsReview() {
      await this.$store.dispatch("review/getNewsReview", { seq: this.seq });
      if (!this.result) {
        this.$toast.default(this.msg);
        return false;
      }

      this.title = this.reviewData.title;
      this.mainImg = this.reviewData.main_img;
      this.thumbnail = this.reviewData.thumbnail;
      this.$refs["mainImage"].src = this.thumbnail;
      this.linkVal = this.reviewData.news_link;
      this.score = this.reviewData.main_score;
      this.content = this.reviewData.content;
      if (
        parseInt(this.categoryNumber1) !== parseInt(this.media) &&
        parseInt(this.categoryNumber1) !== parseInt(this.realTime)
      ) {
        this.addNewsLink(this.newsLink);
      }
    },
    goBack() {
      if (this.mode === "modify" && !this.pageName) {
        this.$router.push(
          `/review/news_main/${this.menu}?category=${this.category}&seq=${this.seq}`
        );
      } else if (this.mode === "modify" && this.pageName === "reviewer") {
        this.$router.push(
          `/review/reviewer_news/${this.memberId}/${this.menu}?category=${this.category}&seq=${this.seq}`
        );
      } else {
        this.$router.push(`/review/review_choice`);
      }
    },
  },
};
</script>

<style lang="scss">
.rn_my_box_28 {
  .cont_info {
    .sect_grade {
      table {
        tbody {
          tr {
            td:nth-child(2) {
              input {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .news_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news_content {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.sel_info ul {
  display: flex;
  li {
    flex-grow: 1;
  }
}

.ql-editor {
  font-size: 13px !important;
}
</style>
