export default {
  methods: {
    async loadImage(target) {
      const files = target.target.value;
      await this.$loadImage(
        files[0],
        function (img) {
          const dataUrl = img.toDataURL("image/jpeg");
        },
        { orientation: true }
      );
    },
  },
};
